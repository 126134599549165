<template>
  <div class="page">
    <img
      src="../../../src/assets/tuoben/lijianjin.png"
    />
    <yd-list style="margin-bottom:2rem" theme="1">
      <yd-list-item v-for="(item, key) in list" :key="key">
        <img slot="img" :src="item.img" />
        <span slot="title" style="float: left;width: 100%;line-height: 0.5rem; height: 0.5rem;">{{ item.name }}</span>
        <yd-list-other slot="other">
          <div class="delis" ref="dec">{{ item.describe }}</div>
        </yd-list-other>
        <yd-list-other slot="other" style="margin-top: 0.15rem;height: 0.3rem;width: 100%;">
          <div>
            <span class="demo-list-price"><em>库存:</em>{{ item.stock }}</span>
          </div>
          <div style="color:#f00">{{ item.price }}积分</div>
        </yd-list-other>
        <yd-list-other slot="other">
          <div class="bug" @click="confirmClear(item)">立即兑换</div>
        </yd-list-other>
      </yd-list-item>
      <div class="text"> 
        <p>兑换说明：</p>
        <span>
          立减金说明：立减金自发放日起30天内有效，过期自动作废。用户在支付时使用工行储蓄卡支付，且金额大于该立减金面值时方可进行抵扣。立减金数量有限，先到先得，享完为止。
        </span>
      </div>
    </yd-list>
     <ConfirmAlert
      v-model="showClearAlert"
      confirmText="确认兑换该商品吗？"
      @cancel="cancelClear"
      @confirm="buy(item)"
    ></ConfirmAlert>
    <tuobenFooter></tuobenFooter>
  </div>
</template>

<script>
import { tabenShopList, exchangeWxCoupon } from "../../services/tuobenLjj";
import tuobenFooter from "@/components/footer/tuobenFooter.vue";
import ConfirmAlert from "@/components/common/ConfirmAlert";
export default {
  name: "SpecialTopic",
  components: {
    tuobenFooter,
    ConfirmAlert
  },
  inject: ["reload"],
  data() {
    return {
      list: [],
      heigh: "0",
      showClearAlert: false,
      item:{}
    };
  },
  created() {
    this.tabenShopList();
  },
  mounted() {},
  methods: {
    cancelClear(){
      this.showClearAlert = false
    },
    confirmClear(item){
      this.showClearAlert = true
      this.item = item
    },
    async buy(item) {
      this.showClearAlert = false
      const res = await exchangeWxCoupon({ wx_coupon_id: item.id });
      if (res.code == 1) {
        this.$dialog.toast({
          mes: res.msg
        });
      } else {
        this.$dialog.toast({
          mes: res.msg
        });
        this.$router.push({
          path: "/tobenOrderList"
        });
      }
      console.log(res);
    },
    async tabenShopList() {
      const res = await tabenShopList();
      console.log(res.data, "res");
      this.list = res.data;
      this.$nextTick(() => {
        this.heigh = Math.max(
          ...this.$refs.dec.map(item => {
            return item.clientHeight;
          })
        );
        this.heigh = `${this.heigh}px`;
        document.body.style.setProperty("--heigh", this.heigh);
      });
    }
  },
  activated() {
    this.name = this.$route.query.name ? this.$route.query.name : "";
    if (this.name) document.title = this.name;
    // this.loadItemList()
  }
  //   beforeRouteEnter(to, from, next) {
  //     next(vm => {
  //       if (from.name !== "goods-detail") {
  //         // vm.reload();
  //         vm.page = 1;
  //         vm.itemList = [];
  //         vm.activeTab = 0;
  //         vm.$refs.pullItemList.$emit("ydui.infinitescroll.reInit");
  //         vm.loadItemList();
  //       }
  //       vm.$store.commit({
  //         type: "getParameterId",
  //         subject_id: to.query.subject_id,
  //         category_id: to.query.category_id
  //       });
  //     });
  //     wxShare();
  //   },
  //   beforeRouteLeave(to, from, next) {
  //     this.$store.commit({
  //       type: "getParameterId",
  //       subject_id: "",
  //       category_id: ""
  //     });
  //     next();
  //   }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.page {
  background:#fcfcfc;
  /deep/ .yd-list-theme3 .yd-list-item {
    margin-bottom: 0.3rem;
  }
  /deep/ .yd-list-title {
    margin-top: 0.2rem;
    height: 0.4rem;
  }
  /deep/ .yd-list-theme1 .yd-list-item:last-child {
    margin-bottom: 1.5rem;
  }
  /deep/.yd-list-theme1 .yd-list-item {
    width: 47%;
    float: left;
    margin: 0.1rem;
    padding: 0.1rem;
    border-radius: 0.2rem;
    position: relative;
    border: 0px;
    z-index: 0;
    background-color: #fff;
  }
  .delis {
    width: 100%;
    height: var(--heigh);
    margin-top: 0.1rem;
  }
  .bug {
    width: 100%;
    height: 0.4rem;
    line-height: 0.5rem;
    text-align: center;
    margin-top: 0.3rem;
    color: #f44336;
    border-top: 1px solid #eee;
  }
  .text{
   float: left;
    margin-bottom: 2rem;
    width: 94%;
    margin-left: 3%;
    line-height: 1.5;
    margin-top: 1rem;
  }
}
</style>
