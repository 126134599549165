var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "nav-container" },
    [
      _c(
        "router-link",
        {
          staticClass: "nav-item flex-center",
          attrs: { to: "tuobenShopList" },
        },
        [
          _vm.$route.name === "tuobenShopList"
            ? _c("icon-svg", {
                staticClass: "main-color",
                attrs: { "icon-class": "yi_ic_home_blue_" },
              })
            : _c("icon-svg", {
                staticClass: "grey",
                attrs: { "icon-class": "yi_ic_home_grey_" },
              }),
          _c("span", { staticClass: "nav-txt" }, [_vm._v("商品主页")]),
        ],
        1
      ),
      _c(
        "router-link",
        {
          staticClass: "nav-item flex-center",
          attrs: { to: "tobenOrderList" },
        },
        [
          _vm.$route.name === "tobenOrderList"
            ? _c("icon-svg", {
                staticClass: "main-color",
                attrs: { "icon-class": "yi_ic_my_blue_" },
              })
            : _c("icon-svg", {
                staticClass: "grey",
                attrs: { "icon-class": "yi_ic_my_grey_" },
              }),
          _c("span", { staticClass: "nav-txt" }, [_vm._v("兑换列表")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }