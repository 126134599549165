
import fetch from "../utils/fetch";
/**
 * 获取商品列表
 */
export const tabenShopList = () =>
  fetch("/api", {
    api_name: "V1.item.TabenWxCoupon.getWxCouponList"
  })
/**
 * 兑换立减金
 */
export const exchangeWxCoupon = params =>
  fetch("/api", {
    api_name: "V1.item.TabenWxCoupon.exchangeWxCoupon",
    ...params
  })
  /**
 * 兑换订单
 */
export const getCouponOrders = params =>
fetch("/api", {
  api_name: "V1.item.TabenWxCoupon.getCouponOrders",
  ...params
})