<template>
  <div class="nav-container">
    <router-link to="tuobenShopList" class="nav-item flex-center">
      <icon-svg
        class="main-color"
        v-if="$route.name === 'tuobenShopList'"
        icon-class="yi_ic_home_blue_"
      />
      <icon-svg class="grey" v-else icon-class="yi_ic_home_grey_" />
      <span class="nav-txt">商品主页</span>
    </router-link>
    <router-link to="tobenOrderList" class="nav-item flex-center">
      <icon-svg
        class="main-color"
        v-if="$route.name === 'tobenOrderList'"
        icon-class="yi_ic_my_blue_"
      />
      <icon-svg class="grey" v-else icon-class="yi_ic_my_grey_" />
      <span class="nav-txt">兑换列表</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'tuobenFooter',
  data() {
    return {}
  },
  created(){
  },
  methods: {}
}
</script>

<style lang="less" scoped>
@import "../../assets/iconfont/iconfont.css";
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.nav-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  .fj;
  align-items: center;
  height: 0.98rem;
  background: #fff;
  box-shadow: 0 -1px 0.2rem 0 rgba(0, 0, 0, 0.1);
  z-index: 99999;

  .nav-item {
    flex: 1;
    flex-direction: column;
    height: 100%;

    .main-color {
      display: inline-block;
      margin-bottom: 0.04rem;
    }

    .icon {
      .wh(0.48rem, 0.48rem);
      .sc(0.48rem, @color-light-grey);
      margin-bottom: 0.08rem;
    }

    .nav-txt {
      .sc(0.22rem, @color-light-grey);
      line-height: 0.32rem;
    }

    .yi_ic_home_blue_,
    .yi_ic_type_blue_,
    .yi_ic_shopping_blue_,
    .yi_ic_my_blue_ {
      color: var(--main-color);
    }

    &.router-link-active .nav-txt {
      color: var(--main-color);
    }

    .grey {
      color: #b3b3b3;
    }
  }
}
</style>