var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("img", {
        attrs: { src: require("../../../src/assets/tuoben/lijianjin.png") },
      }),
      _c(
        "yd-list",
        { staticStyle: { "margin-bottom": "2rem" }, attrs: { theme: "1" } },
        [
          _vm._l(_vm.list, function (item, key) {
            return _c(
              "yd-list-item",
              { key: key },
              [
                _c("img", {
                  attrs: { slot: "img", src: item.img },
                  slot: "img",
                }),
                _c(
                  "span",
                  {
                    staticStyle: {
                      float: "left",
                      width: "100%",
                      "line-height": "0.5rem",
                      height: "0.5rem",
                    },
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [_vm._v(_vm._s(item.name))]
                ),
                _c(
                  "yd-list-other",
                  { attrs: { slot: "other" }, slot: "other" },
                  [
                    _c(
                      "div",
                      { ref: "dec", refInFor: true, staticClass: "delis" },
                      [_vm._v(_vm._s(item.describe))]
                    ),
                  ]
                ),
                _c(
                  "yd-list-other",
                  {
                    staticStyle: {
                      "margin-top": "0.15rem",
                      height: "0.3rem",
                      width: "100%",
                    },
                    attrs: { slot: "other" },
                    slot: "other",
                  },
                  [
                    _c("div", [
                      _c("span", { staticClass: "demo-list-price" }, [
                        _c("em", [_vm._v("库存:")]),
                        _vm._v(_vm._s(item.stock)),
                      ]),
                    ]),
                    _c("div", { staticStyle: { color: "#f00" } }, [
                      _vm._v(_vm._s(item.price) + "积分"),
                    ]),
                  ]
                ),
                _c(
                  "yd-list-other",
                  { attrs: { slot: "other" }, slot: "other" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "bug",
                        on: {
                          click: function ($event) {
                            return _vm.confirmClear(item)
                          },
                        },
                      },
                      [_vm._v("立即兑换")]
                    ),
                  ]
                ),
              ],
              1
            )
          }),
          _c("div", { staticClass: "text" }, [
            _c("p", [_vm._v("兑换说明：")]),
            _c("span", [
              _vm._v(
                "\n        立减金说明：立减金自发放日起30天内有效，过期自动作废。用户在支付时使用工行储蓄卡支付，且金额大于该立减金面值时方可进行抵扣。立减金数量有限，先到先得，享完为止。\n      "
              ),
            ]),
          ]),
        ],
        2
      ),
      _c("ConfirmAlert", {
        attrs: { confirmText: "确认兑换该商品吗？" },
        on: {
          cancel: _vm.cancelClear,
          confirm: function ($event) {
            return _vm.buy(_vm.item)
          },
        },
        model: {
          value: _vm.showClearAlert,
          callback: function ($$v) {
            _vm.showClearAlert = $$v
          },
          expression: "showClearAlert",
        },
      }),
      _c("tuobenFooter"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }